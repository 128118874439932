import { useState } from 'react';
import Typography from 'app/components/Typography';
import useModalStore from 'app/store/modal';
import Button from 'app/components/Button';
import useToast from 'app/hooks/useToast';
import { GridApi } from 'ag-grid-community';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { makeRequest } from 'app/api/processing/processing';

interface DeleteModalProps {
    selectedRows: any[];
}

const DeleteModal: React.FC<DeleteModalProps> = ({ selectedRows }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const params = useParams<{ reportKey: string, organisationId: string }>();
    const navigate = useNavigate();
    const { clearModalContent } = useModalStore();
    const { setNotification } = useToast();

    const handlePrepareForDefence = async () => {
        try {
            const payload = selectedRows.map((row) => row.BILLING_ID);

            setIsSubmitting(true);
            await makeRequest('/Processing/AAAN/sendToDefence', payload);

            navigate(`/organisation/${params.organisationId}/report/aaan-sent-to-defence`);

            setNotification('Prepare for Defence success. \nPlease reload the page after 10-15 seconds for the data to appear.', 'success');
        } catch (error) {
            setNotification('Prepare for Defence error', 'error');
        } finally {
            setIsSubmitting(false);
            clearModalContent();
        }
    };

    return (
        <div className='flex-column'>
            {selectedRows.length > 0
                ? <Typography className='mb-5'>Selected row{selectedRows.length > 1 ? 's' : ''}: {selectedRows.length}</Typography>
                : <Typography className='mb-5'>Please select rows.</Typography>}
            <div className='d-flex justify-content-end gap-3'>
                <Button onClick={clearModalContent} variant='secondary' size='sm'>Cancel</Button>
                {selectedRows.length > 0 && <Button onClick={() => handlePrepareForDefence()} disabled={isSubmitting}
                    size='sm'>Submit</Button>}
            </div>
        </div>
    );
};

const usePrepareForDefence = () => {
    const { setModalContent } = useModalStore();

    const handlePrepareForDefenceModal = (gridApi: GridApi | null) => {
        const selectedRows = gridApi ? gridApi.getSelectedRows() : [];

        setModalContent({
            content: <DeleteModal selectedRows={selectedRows} />,
            title: 'Prepare for Defence'
        });
    };

    return { handlePrepareForDefenceModal };
};

export default usePrepareForDefence;
