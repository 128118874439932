import { createRoot } from 'react-dom/client';
// Axios
import axios from 'axios';
import { Chart, registerables } from 'chart.js';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import * as Sentry from '@sentry/react';
// Apps
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n';
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss';
import './_metronic/assets/sass/plugins.scss';
import './_metronic/assets/sass/style.react.scss';
import './index.scss';
import { AppRoutes } from './app/routing/AppRoutes';
import { AuthInit, AuthProvider, setupAxios } from './app/modules/auth';
import { Auth0Provider } from '@auth0/auth0-react';
import sentryConfig from 'app/utils/sentryConfig';

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios);
Chart.register(...registerables);

Sentry.init(sentryConfig);

const queryClient = new QueryClient();
const container = document.getElementById('root');

const auth0Config = {
    domain: '',
    clientId: ''
};

if (window.location.origin.includes('cargonetwork.com.au')) {
    auth0Config.domain = process.env.REACT_APP_AUTH0_CGNW_DOMAIN!;
    auth0Config.clientId = process.env.REACT_APP_AUTH0_CGNW_CLIENTID!;
} else {
    auth0Config.domain = process.env.REACT_APP_AUTH0_DOMAIN!;
    auth0Config.clientId = process.env.REACT_APP_AUTH0_CLIENTID!;
}

if (container) {
    createRoot(container).render(
        <QueryClientProvider client={queryClient}>
            <MetronicI18nProvider>
                <Auth0Provider
                    domain={auth0Config.domain}
                    clientId={auth0Config.clientId}
                    redirectUri={`${typeof window !== 'undefined' &&
                        window.location.origin}/auth/callback`}
                    audience={process.env.REACT_APP_AUTH0_AUDIENCE!}
                    cacheLocation='localstorage'
                    scope='read:current_user update:current_user_metadata'>
                    <AuthProvider>
                        <AuthInit>
                            <AppRoutes />
                        </AuthInit>
                    </AuthProvider>
                </Auth0Provider>
            </MetronicI18nProvider>

            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
}
