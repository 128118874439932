import NavConfig from 'app/modules/nav-config/NavConfig';
import MetronicDropdown from 'app/components/MetronicDropdown';
import useIsMobile from 'app/hooks/useIsMobile';
import useMailSupport from 'app/hooks/useMailSupport';
import LIME_ICON from 'app/assets/images/lime icon.png';
import LIME_LOGO_WHITE from 'app/assets/images/LIME Logo BIAAS White.png';
import LIME_LOGO_BLACK from 'app/assets/images/LIME Logo BIAAS Tag.png';
import { useThemeMode } from '_metronic/partials';
import AccountMenu from 'app/modules/account-menu/AccountMenu';
import useWhiteLabel from 'app/hooks/useWhiteLabel';
import useRemoveLimeSupport from 'app/hooks/useRemoveLimeSupport';

const SidebarFooter = () => {
    const { menuMode } = useThemeMode();
    const openEmailSupport = useMailSupport();
    const isMobile = useIsMobile();
    const whiteLabelOrg = useWhiteLabel();
    const removeLimeSupport = useRemoveLimeSupport();

    return (
        <div className='app-sidebar-footer flex-column-auto py-6 px-6' id='kt_app_sidebar_footer'>
            {!whiteLabelOrg && <div style={{ height: 55, textAlign: 'center' }}>
                <a href='https://www.limeintel.com/' target='_blank' rel='noreferrer'
                    className='app-sidebar-footer-logo-default d-flex align-items-center justify-content-center pb-5'>
                    <img src={menuMode === 'light' ? LIME_LOGO_BLACK : LIME_LOGO_WHITE} width={125}
                        alt='Lime logo' />
                </a>
                <a href='https://www.limeintel.com/' target='_blank' rel='noreferrer'
                    className='app-sidebar-footer-logo-minimize d-flex align-items-center justify-content-center pb-5'>
                    <img src={LIME_ICON} width={40}
                        alt='Lime logo' />
                </a>
            </div>}
            <MetronicDropdown toggleElement={<div
                className={`btn btn-${whiteLabelOrg || 'primary'} d-flex align-items-center overflow-hidden text-nowrap px-0 h-45px w-100 mb-3`}>
                <span className='btn-label d-flex justify-content-between align-items-center w-100'>
                    <div className='d-flex justify-content-start align-items-center ms-4'>
                        <i className='fa-solid fa-gear w-20px text-white' />
                        <span className='text-white ms-1'>Settings</span>
                    </div>
                    <i style={{ fontSize: 20 }} className='fa-solid fa-caret-right me-3 text-white' />
                </span>
            </div>} placement={isMobile ? 'top-start' : 'right-end'}>
                <NavConfig className='p-5 w-300px' />
            </MetronicDropdown>

            {removeLimeSupport && <button onClick={openEmailSupport} className={`btn d-flex btn-${whiteLabelOrg || 'primary'} justify-content-between align-items-center overflow-hidden text-nowrap px-0 h-45px w-100 mb-3`}>
                <span className='btn-label d-flex'>
                    <div className='d-flex justify-content-start align-items-center ms-4'>
                        <i className='fa-solid fa-question w-20px text-white' />
                        <span className='text-white ms-1'>Lime Support</span>
                    </div>
                </span>
            </button>}
            <MetronicDropdown toggleElement={<div className={`btn btn-${whiteLabelOrg || 'primary'} d-flex align-items-center overflow-hidden text-nowrap px-0 h-45px w-100 mb-3`}>
                <span className='btn-label d-flex justify-content-between align-items-center w-100'>
                    <div className='d-flex justify-content-start align-items-center ms-4'>
                        <i className='fa-solid fa-gear w-20px text-white' />
                        <span className='text-white ms-1'>Account</span>
                    </div>
                    <i style={{ fontSize: 20 }} className='fa-solid fa-caret-right me-3 text-white' />
                </span>
            </div>} placement={isMobile ? 'top-start' : 'right-end'}>
                <AccountMenu />
            </MetronicDropdown>
        </div>
    );
};

export { SidebarFooter };
