import { ReportsType } from 'app/api/reports/types';
import { RightDrawerItem } from 'app/store/rightDrawer';

type UpdateItem = {
    keyName: string;
    content: () => React.ReactNode;
    isShow: boolean;
};

export const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) => {
    return arr.reduce((groups, item) => {
        (groups[key(item)] ||= []).push(item);

        return groups;
    }, {} as Record<K, T[]>);
};

export const trimSpace = (str: string) => str.replace(/^\s+/g, '');

const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 }
];

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const checkIfValueExists = (obj: any, value: string) => Object.values(obj).includes(value);

export const formatReportGroup = (reportGroup: {
    [key: string]: ReportsType[];
}) => {
    return Object.keys(reportGroup).map((key, i) => {
        return { order: parseInt(key.substring(0, key.indexOf('.'))), name: key, data: reportGroup[key].sort((a, b) => a.order - b.order) };
    }).sort((a, b) => a.order - b.order);
};

export const removeNumber = (str: string) => {
    return str.replace(/^[0-9]+\.\s?/, '');
};

export const findChangedObjects = <T>(oldArray: T[], newArray: T[]): T[] => {
    const changedObjects: T[] = [];

    const maxLength = Math.max(oldArray.length, newArray.length);

    for (let i = 0; i < maxLength; i++) {
        const oldObj = oldArray[i];
        const newObj = newArray[i];
        const changedProps: any = {};

        if (!oldObj || !newObj) {
            changedObjects.push(newObj);
            continue;
        }

        for (const key in newObj) {
            if (oldObj[key] !== newObj[key]) {
                changedProps[key] = newObj[key];
            }
        }

        if (Object.keys(changedProps).length > 0) {
            changedObjects.push({ ...newObj, ...changedProps });
        }
    }

    return changedObjects;
};

export const deepCopy = <T>(obj: T): T => {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        const copyArr = obj.map(item => deepCopy(item));

        return copyArr as unknown as T;
    }

    const copyObj: any = {};

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            copyObj[key] = deepCopy(obj[key]);
        }
    }

    return copyObj as T;
};

export const addUnderscoreToFieldName = (data: { [key: string]: string }): { [key: string]: string } => {
    const newData = { ...data };

    const transformedData: { [key: string]: string } = {};

    for (const key in newData) {
        if (Object.hasOwnProperty.call(newData, key)) {
            transformedData['_' + key] = newData[key];
        }
    }

    return transformedData;
};

export const createAgGridColumns = (sheetData: { [key: string]: string }, report: ReportsType) => {
    return Object.keys(sheetData || {}).map((name) => {
        const lowerCaseName = name.toLocaleLowerCase();

        return ({
            field: name,
            width: report.columnWidth ? report.columnWidth[name] : name,
            hide: report.hidden ? report.hidden.includes(name) : false,
            editable: report.readOnly ? !report.readOnly.includes(name) : true,
            headerName: report.columnNames ? report.columnNames[name] : name,
            filter: report.filter ? report.filter[name] : 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
                closeOnApply: true
            },
            cellEditor: report.cellEditor ? report.cellEditor[name] : 'agTextCellEditor',
            cellEditorPopup: report.filter && report.filter[name] === 'agLargeTextCellEditor',
            cellEditorParams: report.cellEditorParams
                ? {
                    ...(report.cellEditorParams[name] || {}),
                    ...Object.keys(report.cellEditorParams).reduce((acc, paramKey) => {
                        const lowercaseKey = paramKey.toLowerCase();

                        if (report.cellEditorParams) {
                            acc[lowercaseKey] = report.cellEditorParams[paramKey];
                        }

                        return acc[lowerCaseName];
                    }, {} as Record<string, any>)
                }
                : {}
        });
    });
};

export const updateRightDrawerData = (updates: UpdateItem[], rightDrawerContent: RightDrawerItem[] | []) => {
    const updatedContent = rightDrawerContent.map((item) => {
        const update = updates.find((u) => u.keyName === item.keyName);

        if (update) {
            return {
                ...item,
                content: update.content,
                isShow: update.isShow
            };
        }

        return item;
    });

    return updatedContent;
};

export const getCurrentDate = () => {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
    const day = String(currentDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
};

export const getCurrentDateAndTime = () => {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

export const getTableauServerURL = () => {
    let domain = '';

    if (window.location.origin.includes('localhost') || window.location.origin.includes('limeintel')) {
        domain = 'limeintel.com';
    } else if (window.location.origin.includes('cargonetwork')) {
        domain = 'cargonetwork.com.au';
    }

    // Construct the new URL with tableau2 subdomain
    const newUrl = `https://tableau2.${domain}`;

    return newUrl;
};
