import React, { useMemo } from 'react';
import { GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

import './ag-grid.scss';

type Props = {
    pageSize: string;
    parsedRawSheetData: any[];
    columnDefs: { [key: string]: any }[]
    handleGridReady: (params: GridReadyEvent) => void;
    onCellValueChanged: () => void;
}

const AgGrid = ({ pageSize, parsedRawSheetData, columnDefs, handleGridReady, onCellValueChanged }: Props) => {
    const defaultColDef = useMemo(() => ({
        filter: true,
        sortable: true,
        resizable: true
    }), []);

    return (
        <AgGridReact
            overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading spreadsheet...</span>'}
            pagination={true}
            paginationAutoPageSize={pageSize === 'dynamic'}
            suppressMenuHide={true}
            animateRows={true}
            singleClickEdit={true}
            rowSelection='multiple'
            suppressColumnVirtualisation={true}
            stopEditingWhenCellsLoseFocus={true}
            undoRedoCellEditing={true}
            undoRedoCellEditingLimit={10}
            onGridReady={handleGridReady}
            defaultColDef={defaultColDef}
            onCellValueChanged={onCellValueChanged}
            rowData={parsedRawSheetData}
            columnDefs={columnDefs} />
    );
};

export default React.memo(AgGrid);
