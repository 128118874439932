type Config = {
    [key: string]: {
        left: ButtonConfig[];
        right: ButtonConfig[];
    };
}

export type ButtonConfig = {
    label: string;
    component: string;
    inputComponent?: {
        type: 'file';
        accept: '.txt' | '.csv';
    };
    apiCall?: {
        endpoint: string;
    };
    saveSheetBeforeAction?: boolean;
    disableWhenDataIsEmpty?: boolean;
    hide?: string[];
    navigateTo?: string;
    successMessage?: string;
}

export const buttonConfig: Config = {
    'aaan-prepare-for-defence': {
        left: [
            {
                label: 'Import Flight Movements',
                component: 'modal',
                inputComponent: {
                    type: 'file',
                    accept: '.txt'
                },
                apiCall: {
                    endpoint: '/Processing/AAAN/processDefenceFlightBilling'
                },
                hide: ['MEMBER'],
                successMessage: 'Import Flight Movements success. \nPlease reload the page after 10-15 seconds for the data to appear.'
            },
            {
                label: 'Prepare for Defence',
                component: 'modal',
                saveSheetBeforeAction: true,
                apiCall: {
                    endpoint: '/Processing/AAAN/sendToDefence'
                },
                hide: ['MEMBER'],
                navigateTo: 'aaan-sent-to-defence',
                successMessage: 'Prepare for Defence success. \nPlease reload the page after 10-15 seconds for the data to appear.'
            }
        ],
        right: [
            {
                label: 'Reset Filters',
                component: 'button',
                disableWhenDataIsEmpty: true
            },
            {
                label: 'Add Row',
                component: 'button',
                disableWhenDataIsEmpty: true
            },
            {
                label: 'Save',
                component: 'button',
                saveSheetBeforeAction: true
            }
        ]
    },
    'aaan-sent-to-defence': {
        left: [
            {
                label: 'Import Defence File',
                component: 'modal',
                inputComponent: {
                    type: 'file',
                    accept: '.csv'
                },
                apiCall: {
                    endpoint: '/AAAN/processReceivedDefenceFile'
                },
                navigateTo: 'aaan-invoice-defence',
                successMessage: 'Import Defence File success. \nPlease reload the page after 10-15 seconds for the data to appear.'
            }
        ],
        right: []
    },
    'aaan-invoice-defence': {
        left: [
            {
                label: 'Invoice Defence',
                component: 'button',
                apiCall: {
                    endpoint: '/Processing/AAAN/invoiceDefence'
                },
                successMessage: 'Invoice Defence success.'
            }
        ],
        right: [
            {
                label: 'Save',
                component: 'button',
                saveSheetBeforeAction: true
            }
        ]
    },
    'aaan-defence-not-approved': {
        left: [],
        right: [
            {
                label: 'Save',
                component: 'button',
                saveSheetBeforeAction: true
            }
        ]
    },
    'aaan-defence-disputed': {
        left: [],
        right: [
            {
                label: 'Save',
                component: 'button',
                saveSheetBeforeAction: true
            }
        ]
    }
};
