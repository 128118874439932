import Button, { ButtonProps } from 'react-bootstrap/Button';
import useWhiteLabel from 'app/hooks/useWhiteLabel';

type Props = ButtonProps & {
    variant?: string; // Make variant prop optional
}

const AppButton = ({ variant = 'primary', ...otherProps }: Props) => {
    const whiteLabelOrg = useWhiteLabel();

    return (
        <Button variant={whiteLabelOrg || variant} {...otherProps} />);
};

export default AppButton;
