import { Link, useParams } from 'react-router-dom';
import { changePassword, useOrganisations } from 'app/api/organisations/organisations';
import Typography from 'app/components/Typography';
import useWhiteLabel from 'app/hooks/useWhiteLabel';
import useToast from 'app/hooks/useToast';
import useModalStore from 'app/store/modal';
import { useAuth } from '../auth';
import './account-menu.scss';

type Props = {}

const AccountMenu = (props: Props) => {
    const { logout, currentUser } = useAuth();
    const params = useParams();

    const { setModalContent } = useModalStore();
    const { setNotification } = useToast();
    const { data } = useOrganisations();
    const whiteLabelOrg = useWhiteLabel();

    const handleChangePassword = async () => {
        if (currentUser) {
            try {
                await changePassword(currentUser?.name);
                setModalContent({ content: <Typography>An email to reset your password has been sent to your registered email address. Please check your inbox and follow the instructions provided in the email to create a new password.</Typography>, title: 'Change Password' });
            } catch (error) {
                setNotification('Change password request failed.', 'error');
            }
        }
    };

    const handleToSelectOrganisation = () => {
        window.location.href = '/organisation';
    };

    const menuLinkClassName = `menu-link -${whiteLabelOrg || 'primary'} px-5`;

    return (
        <div className='account-menu'>
            {params.organisationId && <div className='menu-item px-5'>
                <a onClick={handleChangePassword} className={menuLinkClassName}>
                    Change Password
                </a>
            </div>}
            {data?.data.data.length !== 1 && params.organisationId && <div className='menu-item px-5 my-1'>
                <Link to='' onClick={handleToSelectOrganisation} className={menuLinkClassName}>
                    Switch Organisation
                </Link>
            </div>}
            <div className='menu-item px-5'>
                <a onClick={logout} className={menuLinkClassName}>
                    Sign Out
                </a>
            </div>
        </div>
    );
};

export default AccountMenu;
