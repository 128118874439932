import { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import { MasterInit } from '../_metronic/layout/MasterInit';
import useIsMobile from './hooks/useIsMobile';
import useConfigStore from './store/userConfig';
import { ORIENTATION } from './utils/constants';

const App = () => {
    const isMobile = useIsMobile();

    const { setNavbarOrientationMobile } = useConfigStore();

    useEffect(() => {
        if (isMobile) setNavbarOrientationMobile(ORIENTATION.VERTICAL);
    }, [isMobile]);

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <LayoutProvider>
                <Outlet />
                <MasterInit />
            </LayoutProvider>
        </Suspense>
    );
};

export { App };
