
import { ReportsType } from 'app/api/reports/types';
import { useAuth } from 'app/modules/auth';

const formatNumber = (params: any) => {
    const parts = params.value.toString().split('.');

    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return parts.join('.');
};

const currencyFormatter = (params: any) => {
    return '$' + formatNumber(params);
};

const fourDecimalPlacesFormatter = (params: any) => {
    return params.value.toFixed(4);
};

const displayEmptyIfnull = (params: any) => {
    return params.value || '';
};

const formatters: { [key: string]: (number: number) => void } = {
    thousandsSeparatorFormat: formatNumber,
    currencyFormat: currencyFormatter,
    fourDecimalPlacesFormat: fourDecimalPlacesFormatter
};

const useCreateCoreGridColumns = (sheetData: { [key: string]: string | number }, report: ReportsType) => {
    const { currentUser } = useAuth();
    const accessGroup = currentUser?.app_metadata.accessGroup;

    const createdColumns = Object.keys(sheetData || {}).map((name) => {
        let hiddenForAccessGroup: any = [];
        let readonlyForAccessGroup: any = [];

        if (accessGroup) {
            hiddenForAccessGroup = report.accessGroup.columnHidden?.[accessGroup] || [];
            readonlyForAccessGroup = report.accessGroup.columnReadonly?.[accessGroup] || [];
        }

        const reportReadonly = (report.readOnly || []).concat(readonlyForAccessGroup);
        const reportHidden = (report.hidden || []).concat(hiddenForAccessGroup);

        const cellEditorParams = Object.fromEntries(
            Object.entries(report.cellEditorParams ? report.cellEditorParams : {}).map(([key, value]) => [key.toLowerCase(), value])
        );

        const updatedReport = {
            ...report,
            cellEditorParams
        };

        return ({
            field: name,
            width: updatedReport.columnWidth ? updatedReport.columnWidth[name] : undefined,
            hide: reportHidden.includes(name),
            editable: !reportReadonly.includes(name),
            headerName: updatedReport.columnNames ? updatedReport.columnNames[name] : name,
            filter: updatedReport.filter ? updatedReport.filter[name] : 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
                closeOnApply: true
            },
            type: updatedReport.valueFormatter && formatters[updatedReport.valueFormatter[name]] ? 'rightAligned' : null,
            valueFormatter: name === 'NEXT_PROCESS_NUM' ? displayEmptyIfnull : updatedReport.valueFormatter ? formatters[updatedReport.valueFormatter[name]] : undefined,
            cellEditor: updatedReport.cellEditor ? updatedReport.cellEditor[name] : 'agTextCellEditor',
            cellEditorPopup: updatedReport.cellEditor && updatedReport.cellEditor[name] === 'agLargeTextCellEditor',
            cellEditorParams: updatedReport.cellEditorParams && updatedReport.cellEditorParams[name.toLowerCase()]
                ? {
                    ...(updatedReport.cellEditorParams[name] || {}),
                    ...Object.keys(updatedReport.cellEditorParams).reduce((acc, paramKey) => {
                        if (updatedReport.cellEditorParams && updatedReport.cellEditorParams[paramKey]) {
                            acc = updatedReport.cellEditorParams[paramKey];
                        }

                        return acc || {};
                    }, {} as Record<string, any>)
                }
                : {}
        });
    });

    return createdColumns;
};

export default useCreateCoreGridColumns;
