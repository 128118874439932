import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import Button from 'react-bootstrap/Button';
import useToast from 'app/hooks/useToast';
import useCreateGridColumns from 'app/hooks/useCreateGridColumns';
import { useAuth } from 'app/modules/auth';
import useConfigStore from 'app/store/userConfig';
import useModalStore from 'app/store/modal';
import useRightDrawerStore from 'app/store/rightDrawer';
import AppTooltip from 'app/components/Tooltip';
import Typography from 'app/components/Typography';
import { makeRequest, updateDefenceFlightBilling, useGetData } from 'app/api/processing/processing';
import { ReportsType } from 'app/api/reports/types';
import { CELL_EDITOR_TYPES, FILTER_TYPES, ORIENTATION } from 'app/utils/constants';
import { addUnderscoreToFieldName, findChangedObjects, getCurrentDate, getCurrentDateAndTime, updateRightDrawerData } from 'app/utils/helpers';
import { ButtonConfig, buttonConfig } from './buttonConfig';
import AgGrid from './AgGrid';
import SpreadsheetActions from './SpreadsheetActions/SpreadsheetActions';
import SpreadsheetExport from './SpreadsheetExport';

import './spreadsheet-grid.scss';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useNavigate } from 'react-router-dom';
import useSpreadsheet from '../hooks/useSpreadsheet';
import usePrepareForDefence from './hooks/usePrepareForDefence';

type Props = {
    report: ReportsType;
    className?: string;
}

const triggerRefetchAfterSave = ['aaan-invoice-defence', 'aaan-defence-not-approved'];

const SpreadsheetGridAAAN = ({ report, className }: Props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const params = useParams<{ reportKey: string, organisationId: string }>();
    const { currentUser } = useAuth();
    const navigate = useNavigate();

    const { setRightDrawerContent, rightDrawerContent } = useRightDrawerStore();
    const { navbarOrientation } = useConfigStore();
    const { setModalContent } = useModalStore();

    const modifiedValueFilter = (currentUser?.app_metadata.columnFilter || []).concat(report.dbColumnFilter || []);

    const { data: rawSheetData, isLoading: isSpreadsheetDataLoading, refetch } = useGetData({ db: process.env.REACT_APP_AAAN_DB!, schema: report.schema!, viewOrTable: report.viewOrTable! }, modifiedValueFilter,
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false
        });
    const { setNotification } = useToast();

    const parsedRawSheetData: any[] = useMemo(() => rawSheetData ? JSON.parse(rawSheetData.data.data) : [], [rawSheetData]);
    const createdColumns = useCreateGridColumns(parsedRawSheetData[0] || {}, report);

    const columnNames = useMemo(() => createdColumns, [parsedRawSheetData, report]);

    const { gridApi, handleGridReady, isSheetUpdated, onCellValueChanged, onPageSizeChanged, pageSize, setIsSheetUpdated } = useSpreadsheet(isSpreadsheetDataLoading);

    const { handlePrepareForDefenceModal } = usePrepareForDefence();

    useEffect(() => {
        const rightDrawerContentUpdate = [{
            keyName: 'actions',
            isShow: false,
            content: () => <Typography>No Available Actions</Typography>
        }, {
            keyName: 'export',
            isShow: true,
            content: () => <SpreadsheetExport parsedRawSheetData={parsedRawSheetData} isSheetUpdated={isSheetUpdated} />
        }, {
            keyName: 'filters',
            isShow: false,
            content: () => <div />
        }];

        const updatedContent = updateRightDrawerData(rightDrawerContentUpdate, rightDrawerContent);

        setRightDrawerContent(updatedContent);
    }, [isSheetUpdated, parsedRawSheetData]);

    const handleSaveSpreadsheet = async () => {
        const updatedValues: any[] = [];

        if (gridApi) {
            gridApi.forEachNode((node) => {
                const updatedValue = node.data;

                updatedValues.push(updatedValue);
            });
        }

        if (rawSheetData) {
            const changedObjects = findChangedObjects(JSON.parse(rawSheetData.data.data), updatedValues);

            const modifiedData = changedObjects.map(addUnderscoreToFieldName);

            setIsSaving(true);

            try {
                await updateDefenceFlightBilling(modifiedData);

                if (triggerRefetchAfterSave.includes(params.reportKey!)) {
                    await refetch();
                }

                setNotification('Sheet saved', 'success');
                setIsSheetUpdated(false);
            } catch (error) {
                setNotification('Failed to update spreadsheet', 'error');
            } finally {
                setIsSaving(false);
            }
        }
    };

    const handleAddRowGrid = async () => {
        const newData = columnNames.reduce((acc: { [key: string]: string | number }, column) => {
            if (column.filter === FILTER_TYPES.DATE) {
                acc[column.field] = getCurrentDate();
            } else if (column.filter === FILTER_TYPES.NUMBER) {
                acc[column.field] = 0;
            } else if (column.cellEditor === CELL_EDITOR_TYPES.TIME) {
                acc[column.field] = '00:00:00';
            } else if (column.field === 'BILLING_RECORD_STATUS') {
                acc[column.field] = 'IN-PROGRESS';
            } else if (column.field === 'BILLING_IMPORT_SOURCE') {
                acc[column.field] = 'MANUALLY INSERTED';
            } else if (column.field === 'BILLING_DATE_IMPORTED_UTC') {
                acc[column.field] = getCurrentDateAndTime();
            } else {
                acc[column.field] = '';
            }

            return acc;
        }, {});

        if (gridApi) {
            gridApi.applyTransaction({ add: [newData] })!;

            gridApi.paginationGoToLastPage();
            setNotification('Added a row', 'success');
        }
    };

    const handleModalAction = (config: ButtonConfig) => {
        setModalContent({ content: <SpreadsheetActions config={config} />, title: config.label });
    };

    const handleAsyncButtonAction = async (config: ButtonConfig) => {
        if (config.apiCall) {
            setIsSending(true);

            let payload;

            try {
                if (gridApi && config.apiCall.endpoint === '/Processing/AAAN/sendToDefence') {
                    const selectedRows = gridApi.getSelectedRows();

                    payload = selectedRows.map((row) => [row.BILLING_ID]);
                }

                await makeRequest(config.apiCall.endpoint, payload);

                setNotification(config.successMessage || `${config.label} success.`, 'success', { autoClose: 10000 });

                if (config.navigateTo) {
                    navigate(`/organisation/${params.organisationId}/report/${config.navigateTo}`);
                }
            } catch (error) {
                setNotification(`${config.label} failed`, 'error');
            } finally {
                setIsSending(false);
            }
        }
    };

    return (
        <div>
            <Typography className='w-100 text-left' weight='bolder' size='2x'>{report.reportName}</Typography>
            <div className={`spreadsheet-grid card w-100 ${className}`}>
                <div className='grid-control'>
                    <div className='d-flex justify-content-end gap-3'>
                        {buttonConfig[params.reportKey!]?.left.map((config, i) => {
                            const shouldRender =
                                (config.component === 'modal' || config.component === 'button') &&
                                (!config.hide ||
                                    !currentUser?.app_metadata.accessGroup ||
                                    !config.hide.includes(currentUser.app_metadata.accessGroup));

                            if (!shouldRender) {
                                return <div key={i} />;
                            }

                            if (config.component === 'modal') {
                                if (config.label === 'Prepare for Defence') {
                                    return <Button key={i} size='sm' onClick={() => handlePrepareForDefenceModal(gridApi || null)}>{config.label}</Button>;
                                } else {
                                    return <Button key={i} size='sm' onClick={() => handleModalAction(config)}>{config.label}</Button>;
                                }
                            } else if (config.component === 'button') {
                                return <AppTooltip key={i} className='w-200px' placement='right'
                                    isShow={isSheetUpdated && config.saveSheetBeforeAction}
                                    TooltipContent={<Typography size='8'>There are unsaved changes in the sheet. Please save to enable.</Typography>}>
                                    <Button disabled={config.apiCall ? ((config.saveSheetBeforeAction && isSheetUpdated) || isSending) : false} size='sm' onClick={() => handleAsyncButtonAction(config)}>{config.label}</Button>
                                </AppTooltip>;
                            } else return <div key={i} />;
                        })}
                    </div>
                    <div className='d-flex justify-content-end gap-3'>
                        {buttonConfig[params.reportKey!]?.right.map((config, i) => {
                            if (config.label === 'Add Row') {
                                return <Button key={i} size='sm'
                                    onClick={handleAddRowGrid}>{config.label}</Button>;
                            } else if (config.label === 'Save') {
                                return <Button key={i} disabled={config.saveSheetBeforeAction ? isSaving || !isSheetUpdated : false} size='sm'
                                    onClick={handleSaveSpreadsheet}>{config.label}</Button>;
                            } else if (config.label === 'Reset Filters') {
                                return <Button key={i} size='sm'
                                    onClick={gridApi ? () => gridApi.setFilterModel([]) : () => { }}>{config.label}</Button>;
                            } else return <div key={i} />;
                        })}
                    </div>
                </div>
                <div className={`ag-theme-alpine ${navbarOrientation === ORIENTATION.VERTICAL ? 'vertical' : 'horizontal'}`}>
                    <AgGrid
                        pageSize={pageSize}
                        parsedRawSheetData={parsedRawSheetData}
                        columnNames={columnNames}
                        handleGridReady={handleGridReady}
                        onCellValueChanged={onCellValueChanged} />
                    <div className='d-flex align-items-center justify-content-end my-2'>
                        <p className='fw-semibold fs-4 m-0 me-2'>Page Size:</p>
                        <select value={pageSize} onChange={onPageSizeChanged} id='page-size'>
                            <option value='dynamic'>Dynamic</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                            <option value='300'>300</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpreadsheetGridAAAN;
