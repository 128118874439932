import { useQueryClient, useMutation } from 'react-query';
import makeApiRequest, { useReactQueryRequest, UseApiRequestOptions, Response } from '../makeApiCall';
import { AxiosResponse } from 'axios';

const GET_DATA_DEFENCE_FLIGHT_BILLING = 'getDefenceFlightBilling';
const GET_DATA = 'getData';

export function useDefenceFlightBilling({ ...options }: UseApiRequestOptions<any> = {}) {
    return useReactQueryRequest<any>(GET_DATA_DEFENCE_FLIGHT_BILLING, '/AAAN/getDefenceFlightBilling', { ...options, staleTime: Infinity });
}

export function useGetData(queryParams: { [key: string]: string }, data: any[] = [], { ...options }: UseApiRequestOptions<any> = {}) {
    const queryString = Object.keys(queryParams)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
        .join('&');

    return useReactQueryRequest<any>([`${GET_DATA}-${queryString}`, JSON.stringify(data)], `/Processing/getData?${queryString}`, { ...options, method: 'POST', data });
}

export async function processDefenceFlightBilling(data: any) {
    return await makeApiRequest<any>('/Processing/AAAN/processDefenceFlightBilling', 'POST', data, 'multipart/form-data');
}

export async function processReceivedDefenceFile(data: any) {
    return await makeApiRequest<any>('/AAAN/processReceivedDefenceFile', 'POST', data, 'multipart/form-data');
}

export async function sendToDefence() {
    return await makeApiRequest<any>('/Processing/AAAN/sendToDefence', 'POST');
}

export async function updateDefenceFlightBilling(data: { [key: string]: string; }[]) {
    return await makeApiRequest<any>('/AAAN/updateDefenceFlightBilling', 'POST', data);
}

export async function invoiceDefence() {
    return await makeApiRequest<any>('/Processing/AAAN/invoiceDefence', 'POST');
}

export async function updateCoreData(data: { [key: string]: string; }[], scheduleName: string | undefined) {
    return await makeApiRequest<any>('/Processing/CORE/updateCoreData', 'POST', data, 'application/json', { scheduleName });
}

export function useUpdateDefenceFlightBilling() {
    const queryClient = useQueryClient();

    const mutation = useMutation<AxiosResponse<Response<any>, any>, unknown, { [key: string]: string; }[]>(
        (data) => updateDefenceFlightBilling(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: [GET_DATA] });
            }
        }
    );

    return mutation;
}

export async function fileUpload(endpoint: string, data: FormData) {
    return await makeApiRequest<any>(endpoint, 'POST', data, 'multipart/form-data');
}

export async function makeRequest(endpoint: string, data?: any) {
    return await makeApiRequest<any>(endpoint, 'POST', data);
}
