import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';

import TableauReport from 'app/modules/tableau-report/TableauReport';
import useTableauStore from 'app/store/tableau';
import useFilteredReports from 'app/hooks/useFilteredReports';
import { getReportToken } from 'app/api/reports/reports';
import { ReportsType } from 'app/api/reports/types';
import { useAuth } from 'app/modules/auth';
import { getTableauServerURL } from 'app/utils/helpers';
import SpreadsheetGridAAAN from '../SpreadsheetGrid/AAAN';
import SpreadsheetGridCORE from '../SpreadsheetGrid/CORE/SpreadsheetGridCORE';
import Chart from '../Chart';

const AAAN_ORG_ID = ['org_Jnpx9GZs3saF44RG', 'org_XYnzUge2Laf0JE4O'];
const ZEST_ORG_ID = ['org_sH7QJASGdvGzD5VM', 'org_UF7b2zropOfeQo6R'];
const CORE_ORG_ID = ['org_6j3jD0R0YGUUNNeq', 'org_UgbmAFKuhGa0B13F'];

const OrganisationReport = () => {
    const [url, setUrl] = useState('');
    const [reportType, setReportType] = useState<ReportsType['type'] | 'loading'>('loading');
    const [currentReport, setCurrentReport] = useState<ReportsType>();
    const { currentUser } = useAuth();

    const params = useParams();

    useEffect(() => {
        const startIndex = window.location.pathname.indexOf('/report/');
        const output = window.location.pathname.substring(startIndex);

        ReactGA.event({
            category: `userView - ${process.env.REACT_APP_CURRENT_ENV}`,
            action: `${output} - ${process.env.REACT_APP_CURRENT_ENV}`,
            label: `${currentUser?.sub} - ${process.env.REACT_APP_CURRENT_ENV}`
        });
    }, [params]);

    const { setParameters, setFilters } = useTableauStore();

    const { data } = useFilteredReports();

    useEffect(() => {
        if (params.reportKey && data) {
            const getCurrentReport = async () => {
                setParameters(null);
                setFilters(null);

                const report = data.find((data) => data.key === params.reportKey);

                let url = '';

                if (report?.type === 'Legacy') {
                    url = `https://admin.limeintel.com/includes/token_access.php?${report?.viewParams}`;
                    setReportType('Legacy');
                } else if (report?.type === 'Tableau') {
                    const response = await getReportToken(params.reportKey!);

                    url = `${getTableauServerURL()}/trusted/${response.data.data
                        }/t/${report?.targetSite || 'dev'}/views/${report?.viewParams}`;
                    setReportType('Tableau');
                } else if (report?.type === 'spreadsheet') {
                    setReportType('spreadsheet');
                } else if (report?.type === 'chart') {
                    setReportType('chart');
                }

                setCurrentReport(report);
                setUrl(url);
            };

            getCurrentReport();
        }
    }, [params.reportKey, data]);

    if (reportType === 'Legacy' || reportType === 'Tableau') {
        return <TableauReport url={url} />;
    } else if (reportType === 'spreadsheet' && currentReport) {
        if (AAAN_ORG_ID.includes(params.organisationId!) || ZEST_ORG_ID.includes(params.organisationId!)) {
            return <SpreadsheetGridAAAN key={currentReport.key} report={currentReport} />;
        } else if (CORE_ORG_ID.includes(params.organisationId!)) {
            return <SpreadsheetGridCORE key={currentReport.key} report={currentReport} />;
        } else return <div />;
    } else if (reportType === 'chart') {
        return <Chart />;
    } else return <div />;
};

export default OrganisationReport;
